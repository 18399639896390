import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  afterNextRender,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnsembleService } from '@jfw-library/ecommerce/core';
import { getEnsembleDesigner, getImage } from 'business-logic';
import { EcomEnsemble, EcomEnsemblePLP, Image } from 'common-types';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, AfterViewChecked {
  ensembles$: Observable<EcomEnsemble[]> | undefined;

  constructor(
    private cdRef: ChangeDetectorRef,
    private ensembleService: EnsembleService,
    public dialog: MatDialog,
  ) {
    // afterRender(() => {
    //   console.log('After render');
    // });

    afterNextRender(() => {
      console.log('After next render');
      // this.ensembles$ = this.ensembleService.getTrendingEnsembles().pipe(
      //   tap((ensembles) => console.log("Ensembles received"))
      // )
    });
  }

  ngOnInit(): void {
    this.ensembles$ = this.ensembleService
      .getTrendingEnsembles()
      .pipe(tap((ensembles) => console.log('Ensembles received.')));
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  public getImage(ensemble: EcomEnsemble): Image {
    return getImage(ensemble);
  }

  public getDesigner(ensemble: EcomEnsemble): string {
    return getEnsembleDesigner(ensemble);
  }

  getEnsembleTitleAndCode(ensemble: EcomEnsemblePLP) {
    // Replace spaces with hyphens and make the string URL-safe
    const title = encodeURIComponent(
      ensemble.title.toLowerCase().replace(/\s+/g, '-'),
    );
    const titleAndCode = `/ensemble/${title}-${ensemble.ensembleCode}`;
    return titleAndCode;
  }

  // public getStarted(): void {
  //   this.dialog.open(GetStartedModalComponent, {
  //     width: '400px',
  //     maxWidth: '300px',
  //     height: '600px',
  //     autoFocus: false,
  //     disableClose: true,
  //   });
  // }
}
