import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SearchService } from '@jfw-library/ecommerce/core';
import { DealerAccountElastic } from 'common-types';
import { SEOService } from '../../services/seo/seo-service';
type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};
interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class StoreDetailsResolver implements Resolve {
  private search = inject(SearchService);
  private seoService = inject(SEOService);
  private router = inject(Router);
  constructor() {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let storeInfoString: string = route.params.storeId;
    let storeInfoArray = storeInfoString.split('-');
    let metaData: MetaInfo = {
      metaDescription: undefined,
      metaTitle: undefined,
      noIndex: true,
      metaKeywords: undefined,
    };
    const storeData = this.search
      .getStoreByActNum(storeInfoArray[storeInfoArray.length - 1])
      .subscribe((store) => {
        if (store.hits.hits.length && store.hits.hits[0]._source) {
          const foundStore = store.hits.hits[0]._source as DealerAccountElastic;
          const name = foundStore?.aname;
          const description =
            name +
            ", a Jim's Formal Wear affiliated tuxedo rental store in " +
            foundStore.city +
            ', ' +
            foundStore.state +
            '.';
          const title = name;

          metaData.metaDescription = description;
          metaData.metaTitle = title;
          metaData.noIndex = foundStore ? false : true;
          metaData.metaKeywords = undefined;
          route.data = { seoParams: metaData };

          // Check if it is a parameterized route (we do not index paramterized routes)
          const { queryParams } = route;
          const isParameterizedRoute = Object.keys(queryParams).length > 0;

          if (isParameterizedRoute) {
            this.seoService.setNoIndexTag(true);
            /**
             * If noIndex is ever true, canonical should be removed
             * Default remove canonical.
             *  */
            this.seoService.setCanonicalTag(state.url, false);
          } else {
            this.seoService.setNoIndexTag(metaData?.noIndex ?? undefined);
            this.seoService.setCanonicalTag(state.url, !metaData.noIndex);
          }
          this.seoService.setMetaTags(metaData);
        } else {
          this.router.navigate(['store-locator']);
        }
      });

    return storeData;
  }
}
