import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StyleService } from '@jfw-library/ecommerce/core';
import { SEOService } from '../../services/seo/seo-service';
type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};
interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class StyleResolver implements Resolve {
  private styleService = inject(StyleService);
  private seoService = inject(SEOService);
  constructor() {}
  private getFilteredDescriptionForMeta(
    description: string | undefined,
  ): string {
    if (description) {
      let descriptionWithoutLineBreaks = description.replace(/\n|\r/g, '');
      let descriptionWithoutHashes = descriptionWithoutLineBreaks.replace(
        /[#-]/g,
        '',
      );
      return descriptionWithoutHashes.substring(0, 160);
    }
    return '';
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let styleCodeString: string = state.url;
    let styleCodeArray = styleCodeString.split('-');
    // This if statement is to know that we came from products search page skip the api call below.
    if (styleCodeArray[styleCodeArray.length - 1].includes('searchText')) {
      return;
    }

    const stylesInfo = this.styleService
      .getStyleByStyleCode(styleCodeArray[styleCodeArray.length - 1])
      .subscribe((style) => {
        const metaData: MetaInfo = {
          metaDescription: undefined,
          metaTitle: undefined,
          noIndex: true,
          metaKeywords: undefined,
        };
        if (style) {
          const title = style?.marketingTitle;
          let metaDescription = style.metaDescription;
          if (
            metaDescription === undefined ||
            metaDescription === null ||
            metaDescription === ''
          ) {
            metaDescription = this.getFilteredDescriptionForMeta(
              style.marketingDescription,
            );
          }

          const keywords = style.keywords
            ? style.keywords.join(',')
            : undefined;

          metaData.metaDescription = metaDescription;
          metaData.metaTitle = title;
          metaData.noIndex = false;
          metaData.metaKeywords = keywords;
        }
        const { queryParams } = route;
        const isParameterizedRoute = Object.keys(queryParams).length > 0;

        if (isParameterizedRoute) {
          this.seoService.setNoIndexTag(true);
          /**
           * If noIndex is ever true, canonical should be removed
           * Default remove canonical.
           *  */
          this.seoService.setCanonicalTag(state.url, false);
        } else {
          this.seoService.setNoIndexTag(metaData?.noIndex ?? undefined);
          this.seoService.setCanonicalTag(state.url, !metaData.noIndex);
        }
        this.seoService.setMetaTags(metaData);
      });
  }
}
