import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EnsembleService } from '@jfw-library/ecommerce/core';
import { getEnsembleDesigner } from 'business-logic';
import { SEOService } from '../../services/seo/seo-service';
type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};
interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class EnsembleResolver implements Resolve {
  private ensembleService = inject(EnsembleService);
  private seoService = inject(SEOService);
  constructor() {}
  private getFilteredDescriptionForMeta(
    description: string | undefined,
  ): string {
    if (description) {
      //let filteredDescription = description.replace(/[#-]/g, '');
      let descriptionWithoutLineBreaks = description.replace(/\n|\r/g, '');
      let descriptionWithoutHashes = descriptionWithoutLineBreaks.replace(
        /[#-]/g,
        '',
      );
      return descriptionWithoutHashes.substring(0, 160);
    }
    return '';
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let ensembleCodeString: string = state.url;
    let ensembleCodeArray = ensembleCodeString.split('-');
    // This if statement is to prevent the excess api call from products search page
    if (
      ensembleCodeArray[ensembleCodeArray.length - 1].includes('searchText')
    ) {
      return;
    }
    const ensembleInfo = this.ensembleService
      .getEnsembleByEnsembleCode(
        ensembleCodeArray[ensembleCodeArray.length - 1],
      )
      .subscribe((ensemble) => {
        const title = getEnsembleDesigner(ensemble) + ' ' + ensemble.title;
        let metaDescription = ensemble.metaDescription;
        if (
          metaDescription === undefined ||
          metaDescription === null ||
          metaDescription === ''
        ) {
          metaDescription = this.getFilteredDescriptionForMeta(
            ensemble.description,
          );
        }

        const keywords = ensemble.keywords
          ? ensemble.keywords.join(',')
          : undefined;

        const metaInfo: MetaInfo = {
          metaDescription,
          metaTitle: title,
          noIndex: false,
          metaKeywords: keywords,
        };

        // Check if it is a parameterized route (we do not index paramterized routes)
        const { queryParams } = route;
        const isParameterizedRoute = Object.keys(queryParams).length > 0;

        if (isParameterizedRoute) {
          this.seoService.setNoIndexTag(true);
          /**
           * If noIndex is ever true, canonical should be removed
           * Default remove canonical.
           *  */
          this.seoService.setCanonicalTag(state.url, false);
        } else {
          this.seoService.setNoIndexTag(metaInfo?.noIndex ?? undefined);
          this.seoService.setCanonicalTag(state.url, !metaInfo.noIndex);
        }
        this.seoService.setMetaTags(metaInfo);
      });
  }
}
